<template>
  <c-table
    ref="table"
    tableId="table"
    :title="title"
    :columnSetting="false"
    :isFullScreen="false"
    :isExcelDown="false"
    :columns="grid.columns"
    :data="grid.data"
    selection="multiple"
    rowKey="govScheduleHistoryId"
  >
    <!-- 버튼 영역 -->
    <template slot="table-button">
      <q-btn-group outline>
        <c-btn
          v-if="editable"
          label="LBLADD"
          icon="add"
          @btnClicked="addInfo" />
        <c-btn
          v-if="editable"
          label="LBLREMOVE"
          icon="delete"
          @btnClicked="deleteInfo" />
        <c-btn
          v-if="editable"
          :url="saveUrl"
          :isSubmit="isSave"
          :param="grid.data"
          mappingType="PUT"
          label="LBLSAVE"
          icon="save"
          @beforeAction="saveInfo"
          @btnCallback="saveCallback" />
      </q-btn-group>
    </template>
  </c-table>
</template>
<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'govScheduleHistory',
  props: {
    param: {
      type: Object,
      default: () => ({
        govScheduleMstId: '',
      }),
    },
    data: {
      type: Object,
      default: () => ({
        govScheduleMstId: '',  // 대관업무 마스터 일련번호
        govScheduleTypeCd: null,  // 대관업무구분
        plantCd: null,  // 사업장코드
        govScheduleName: '',  // 대관업무명
        govScheduleCycleCd: null,  // 법정 주기
        remarks: '',  // 내용
        useFlag: 'Y',  // 사용여부
        inspType: null,  // 검사-종류
        relatedLawsCd: null,  // 검사-관련법규
        inspTarget: '',  // 검사-대상 시설
        contStatus: null,  // 계약-상태
        contType: null,  // 계약-구분
        contVendor: '',  // 계약-업체
        users: [],
        deleteUsers: [],
      }),
    },
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'execDt',
            field: 'execDt',
            label: '대관업무 실시일자',
            align: 'center',
            style: 'width:150px',
            sortable: true,
            type: 'date',
            required: true
          },
          {
            name: 'deptName',
            field: 'deptName',
            label: '대관업무 실시자 부서',
            align: 'center',
            style: 'width:150px',
            sortable: true,
          },
          {
            name: 'execUserName',
            field: 'execUserName',
            label: '대관업무 실시자',
            align: 'center',
            style: 'width:150px',
            sortable: true,
            type: 'user',
            userId: 'execUserId',
            relationCols: ['deptCd', 'deptName'],
            required: true
          },
          {
            name: 'remarks',
            field: 'remarks',
            label: '업무내용',
            align: 'left',
            sortable: true,
            type: 'textarea',
          },
        ],
        data: [],
      },
      listUrl: '',
      saveUrl: '',
      deleteUrl: '',
      editable: true,
      isSave: false,
      saveType: 'POST',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    title() {
      return '대관업무 이력 ('+this.data.govScheduleTypeName+' > '+this.data.govScheduleName+')'
    }
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.env.gov.history.list.url;
      this.saveUrl = transactionConfig.env.gov.history.save.url;
      this.deleteUrl = transactionConfig.env.gov.history.delete.url;
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {govScheduleMstId: this.param.govScheduleMstId};
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    addInfo() {
      this.grid.data.splice(0, 0, {
        govScheduleHistoryId: uid(),  // 대관업무 이력 일련번호
        govScheduleMstId: this.param.govScheduleMstId,  // 대관업무 마스터 일련번호
        execDt: this.$comm.getToday(),  // 실시일자
        execUserName: this.$store.getters.user.userName,  // 대관업무 실시자
        execUserId: this.$store.getters.user.userId,  // 대관업무 실시자
        deptCd: this.$store.getters.user.deptCd,
        deptName: this.$store.getters.user.deptName,
        remarks: '',  // 내용
        regUserId: this.$store.getters.user.userId,
        editFlag: 'C',
      });
    },
    saveInfo() {
      if (this.$comm.validTable(this.grid.columns, this.grid.data)) {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGSAVE', // 저장하시겠습니까?
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.isSave = !this.isSave;
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      } 
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getList();
    },
    deleteInfo() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGREMOVE', // 삭제하시겠습니까?,
          // TODO : 필요시 추가하세요.
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.deleteUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              this.$_.forEach(selectData, item => {
                this.grid.data = this.$_.reject(this.grid.data, item);
              })
              this.$refs['table'].$refs['compo-table'].clearSelection();
              window.getApp.$emit('APP_REQUEST_SUCCESS');
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    deleteCallback() {
    },
  }
};
</script>