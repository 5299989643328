var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "c-table",
    {
      ref: "table",
      attrs: {
        tableId: "table",
        title: _vm.title,
        columnSetting: false,
        isFullScreen: false,
        isExcelDown: false,
        columns: _vm.grid.columns,
        data: _vm.grid.data,
        selection: "multiple",
        rowKey: "govScheduleHistoryId",
      },
    },
    [
      _c(
        "template",
        { slot: "table-button" },
        [
          _c(
            "q-btn-group",
            { attrs: { outline: "" } },
            [
              _vm.editable
                ? _c("c-btn", {
                    attrs: { label: "LBLADD", icon: "add" },
                    on: { btnClicked: _vm.addInfo },
                  })
                : _vm._e(),
              _vm.editable
                ? _c("c-btn", {
                    attrs: { label: "LBLREMOVE", icon: "delete" },
                    on: { btnClicked: _vm.deleteInfo },
                  })
                : _vm._e(),
              _vm.editable
                ? _c("c-btn", {
                    attrs: {
                      url: _vm.saveUrl,
                      isSubmit: _vm.isSave,
                      param: _vm.grid.data,
                      mappingType: "PUT",
                      label: "LBLSAVE",
                      icon: "save",
                    },
                    on: {
                      beforeAction: _vm.saveInfo,
                      btnCallback: _vm.saveCallback,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }